import { useCallback } from "react";
import useModalState from "src/modals/hooks/useModalState";
import { IndividualModalConfig } from "src/modals/types";
import { Timestamp_MS } from "src/shared/types/general";

const useCloseFirstModal = ({
	name,
	config,
}: {
	name: string;
	config: IndividualModalConfig<any, any>;
}) => {
	const closeFirstModal = useCallback(() => {
		if (config.allowClose ?? true) {
			const { close, openLookup } = useModalState.getState();
			const openModals: { name: string; openedAt: Timestamp_MS }[] = [];
			for (const modalName in openLookup) {
				const modal = openLookup[modalName];
				if (modal && modal.isOpen && modal.openedAt) {
					openModals.push({ name: modalName, openedAt: modal.openedAt });
				}
			}
			const orderedModals = openModals.sort((a, b) => b.openedAt - a.openedAt);
			const firstModal = orderedModals[0];
			if (firstModal && firstModal.name === name) {
				close(name);
			}
		}
	}, [config, name]);

	return {
		closeFirstModal,
	};
};

export default useCloseFirstModal;
